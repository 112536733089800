.cta {

    .form-input {
        border-color: transparent;
        border-radius: 2px;
    }
}

.cta-inner {
	padding-left: 16px;
	padding-right: 16px;
    background: -moz-linear-gradient(left #0fc2e2 0%, #0b62bf 100%);
    background: -webkit-gradient(linear, top left, top right, color-stop(0%,#0fc2e2), color-stop(100%,#0b62bf));
    background: -webkit-linear-gradient(left, #0fc2e2 0%,#0b62bf 100%);
    background: -o-linear-gradient(left, #0fc2e2 0%,#0b62bf 100%);
    background: -ms-linear-gradient(left, #0fc2e2 0%,#0b62bf 100%);
    background: linear-gradient(to right, #0fc2e2 0%,#0b62bf 100%);    
}

@include media( '>medium' ) {

.cta {

    .form-input {
        min-width: 280px;
    }
}    

	.cta-inner {
		padding-left: 48px;
		padding-right: 48px;
	}
}
